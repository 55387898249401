import { ManualCommunication } from '@root/src/types/GCB2'

export default function() {
    const findError = (communications: ManualCommunication[] | ManualCommunication) => {
        const toShortPhoneError = () => {
            if (Array.isArray(communications)) {
                return communications.some(item => item.hrefInfo[0].phone.replace(/\D/g, '').length < 11)
            } else {
                return communications.hrefInfo[0].phone.replace(/\D/g, '').length < 11
            }
        }
        if (toShortPhoneError()) {
            if (Array.isArray(communications)) {
                return 'Один из номеров телефонов длиной меньше 11 цифр'
            } else {
                return 'Номер телефона короче 11 цифр'
            }
        }
    }

    return {
        findError,
    }
}
