<template>
    <div>
        <div class="d-flex justify-center align-center" v-if="initialLoading">
            <v-progress-circular indeterminate color="main"> </v-progress-circular>
        </div>
        <div class="task-list" v-show="!initialLoading">
            <ServiceBanDialog v-model="serviceBanDialogIsOpened"></ServiceBanDialog>
            <ClientBanDialog v-model="clientBanDialogIsOpened"></ClientBanDialog>
            <ActionModal></ActionModal>
            <ErrorModal></ErrorModal>
            <BatchModeAddDailyLimitModal></BatchModeAddDailyLimitModal>
            <v-row class="filters ma-0">
                <DataFilterBackendOutlined
                    @searchInput="changeClientItems"
                    @stateChanged="e => (selectedClientsState = e)"
                    @modeChanged="e => (selectedClientsMode = e)"
                    :newStateAndMode="clientsFilterNewStateAndMode"
                    v-model="selectedClients"
                    class="filter"
                    :maxValuesCount="maxClientsCount"
                    :items="clientsList"
                    filled
                    label="КЛИЕНТ"
                    :hideDetails="true"
                    style="max-width: 165px; min-width: 165px"
                >
                </DataFilterBackendOutlined>
                <CustomActivatorSelect
                    class="filter"
                    :items="activationsList"
                    v-model="selectedActivationsIds"
                    :multiple="true"
                >
                    <template v-slot:activator="{ selected, _id }">
                        <BaseInputOutlined
                            hide-details
                            class="select-input"
                            append-icon="mdi-menu-down"
                            :id="_id"
                            :label="`АКТИВАЦИЯ(${selected.length})`"
                            :boldPlaceholder="true"
                            readonly
                            hideDetails
                        >
                        </BaseInputOutlined>
                    </template>
                </CustomActivatorSelect>

                <PhoneFilter
                    class="filter"
                    style="max-width: 165px; min-width: 165px"
                    v-model="selectedPhone"
                    @submit="applyFilters"
                >
                </PhoneFilter>
                <VisitCountFilter
                    class="filter"
                    style="max-width: 250px; min-width: 250px"
                    v-model="selectedVisitCount"
                    :maxClientVisits="maxClientVisits"
                    :minClientVisits="minClientVisits"
                ></VisitCountFilter>
            </v-row>
            <v-row class="mx-0 mt-4 data-control-btns">
                <v-btn color="main" outlined class="white--text" @click="applyFilters">Применить</v-btn>
                <v-btn outlined class="ml-6 cancel-filters-btn" @click="dropFilters">Сбросить</v-btn>
            </v-row>
            <v-data-table
                ref="dTable"
                :loading="loading"
                class="expandable-table"
                :class="{ 'table-loading': loading }"
                :headers="[
                    { text: 'Статус', value: 'group', sortable: false, width: '100%' },
                    { text: '', value: 'action', sortable: false },
                    { text: '', value: 'buttons' },
                ]"
                :item-class="() => 'expandable-row'"
                :items="displayedGroupedData"
                :expanded.sync="expanded"
                :items-per-page="-1"
                hide-default-footer
                @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                show-expand
                item-key="status"
            >
                <template v-slot:expanded-item="{ headers, item }">
                    <td
                        :id="Object.keys(TASKLIST_STATUSES).find(k => TASKLIST_STATUSES[k] === item.status)"
                        :colspan="headers.length"
                        style="padding: 0px; border: 0"
                    >
                        <v-data-table
                            v-if="!project.billingEnabled || (project.billingEnabled && currentTariff)"
                            ref="table"
                            @current-items="rowsOrderChanged($event)"
                            :headers="getTableHeaders(item)"
                            :items="item.data"
                            :items-per-page="5"
                            :footerProps="footerProps"
                            :page="defaultPage[item.status]"
                            @click:row="rowClick"
                            class="recomendations-table expanded-table"
                            @update:sort-by="updateSortBy(item, $event)"
                            @update:sort-desc="updateSortDesc(item, $event)"
                            :sort-by.sync="defaultSortBy[item.status]"
                            :sort-desc.sync="defaultSortDesc[item.status]"
                            color="main"
                            :item-class="getHighlightedClass"
                        >
                            <template v-slot:[`header.checkboxes`]="{}">
                                <BatchModeHeaderCheckbox
                                    :batchModeSelectedCommunicationsIds="batchModeSelectedCommunicationsIds"
                                    :currentItems="currentItems"
                                    :batchModeSelectLimit="batchModeSelectLimit"
                                    :item="item"
                                />
                            </template>
                            <template v-slot:[`item.checkboxes`]="{ item }">
                                <BatchModeRowCheckbox
                                    :batchModeSelectedCommunicationsIds="batchModeSelectedCommunicationsIds"
                                    :batchModeSelectLimit="batchModeSelectLimit"
                                    :item="item"
                                    @input="rowClick(item)"
                                />
                            </template>
                            <template v-slot:[`item.clientNameWithNum`]="{ item }">
                                <span :class="getColorClass(item)" class="client-name">
                                    {{ item.clientNameWithNum }}
                                </span>
                                <br />
                                <PhoneLink
                                    :hrefInfo="item.hrefInfo"
                                    :highlitedText="selectedPhone"
                                    :hide="!checkRole(project.id, 'gcb2:tasklist:clientPhone:table:view')"
                                ></PhoneLink>
                            </template>
                            <template v-slot:[`item.serviceTitle`]="{ item }">
                                <span :class="getColorClass(item)" class="client-name">
                                    {{ item.serviceTitle }}
                                </span>
                                <br />
                            </template>
                            <template v-slot:[`item.loyality`]="{ item }">
                                <v-row class="ma-0">
                                    <span :class="getColorClass(item)">
                                        {{ item.loyality.visitCountText }}
                                    </span>
                                </v-row>
                                <v-row class="ma-0">
                                    <span :class="getColorClass(item)">
                                        {{ item.loyality.moneySpent }}
                                    </span>
                                </v-row>
                            </template>
                            <template v-slot:[`item.activation`]="{ item }">
                                <span :class="getColorClass(item)">
                                    <v-row class="ma-0">
                                        <span :class="getColorClass(item)" class="activation-name">
                                            {{ item.activation.name }}
                                        </span>
                                    </v-row>
                                </span>
                            </template>
                            <template v-slot:[`item.communication`]="{ item }">
                                <span :class="getColorClass(item)">
                                    <v-row class="ma-0">
                                        <span :class="getColorClass(item)">
                                            {{ item.communication.name }}
                                        </span>
                                    </v-row>
                                    <v-row class="ma-0">
                                        <span :class="getColorClass(item)">
                                            {{ item.communication.date }}
                                        </span>
                                    </v-row>
                                </span>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="table-buttons-wrapper">
                                    <AmoCrmLink :hrefInfo="item.hrefInfo"></AmoCrmLink>
                                    <YClientsLink :hrefInfo="item.hrefInfo"></YClientsLink>
                                    <ProfSalonLink :hrefInfo="item.hrefInfo"></ProfSalonLink>
                                    <SonlineLink :hrefInfo="item.hrefInfo"></SonlineLink>
                                    <AltegioLink :hrefInfo="item.hrefInfo"></AltegioLink>
                                    <DikidiLink :hrefInfo="item.hrefInfo"></DikidiLink>
                                    <ChatLink
                                        :hrefInfo="item.hrefInfo"
                                        :chatLinkSettings="connector.whatsappLinkSettings"
                                        :textMessage="getChatTextMessage(item)"
                                    ></ChatLink>
                                    <SendMessageErrorBadge
                                        :item="item"
                                        v-if="getError(item) || item.messageError"
                                        :message="getError(item)"
                                    />

                                    <ActionsContextMenu
                                        v-if="item.status !== 'В очереди на отправку'"
                                        :item="item"
                                    ></ActionsContextMenu>
                                </div>
                            </template>
                        </v-data-table>
                        <DemoSubView
                            v-else
                            :backroundLink="
                                require('@/assets/img/project/expiredTariff/activation-users-demo.png')
                            "
                        ></DemoSubView>
                    </td>
                </template>
                <template v-slot:[`item.buttons`]="{ item }">
                    <v-row class="ma-0" justify="end">
                        <v-btn
                            outlined
                            color="main"
                            v-if="item.status === TASKLIST_STATUSES.DONT_REMIND_SERVICE"
                            @click.stop="serviceBanDialogIsOpened = true"
                            small
                        >
                            <v-icon>mdi-plus</v-icon>ДОБАВИТЬ
                        </v-btn>
                        <v-btn
                            outlined
                            color="main"
                            v-else-if="item.status === TASKLIST_STATUSES.DONT_REMIND"
                            @click.stop="clientBanDialogIsOpened = true"
                            small
                        >
                            <v-icon>mdi-plus</v-icon>ДОБАВИТЬ
                        </v-btn>
                        <v-btn
                            outlined
                            :disabled="waBtnDisabled"
                            class="outlined-btn"
                            v-if="getShowBatchModeButton(item.status)"
                            @click.stop="changeBatchModeStatus(item.status)"
                            small
                        >
                            {{ batchModeButtonLabel }}
                        </v-btn>
                        <v-btn
                            text
                            disabled
                            v-if="item.status === TASKLIST_STATUSES.IN_PROGRESS"
                            small
                        >
                        Статус обновляется раз в 5 минут
                        </v-btn>
                    </v-row>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import { getClientRef } from '@root/src/components/chisai/getClientsBack/helpers.ts'
import {
    DEFAULT_TASKLIST_BATCH_MODE_SELECT_LIMIT,
    SIDEBAR_SECTIONS,
    TASKLIST_ACTION_TYPES,
    TASKLIST_STATUSES,
} from '@/vars/GCB2.js'
import { groupBy, camelize } from '@/utils'
import { checkRole } from '@/helpers/roles'
import { openInNewTab } from '@/utils.js'
import YClientsLink from '@/components/iconLinks/YClientsLink'
import AltegioLink from '@/components/iconLinks/AltegioLink'
import ProfSalonLink from '@/components/iconLinks/ProfSalonLink'
import SonlineLink from '@/components/iconLinks/SonlineLink'
import dikidiLink from '@/assets/vuetifyIcons/dikidi.vue'
import PhoneLink from '@/components/iconLinks/PhoneLink'
import * as t from '@/store/chisai/GCB2/actionTypes'
import TextField from '@root/src/components/inputs/TextField.vue'
import VisitCountFilter from '@root/src/components/chisai/GCB2/taskList/VisitCountFilter.vue'
import ActionsContextMenu from '@root/src/components/chisai/GCB2/taskList/ActionsContextMenu.vue'
import ActionModal from '@/components/chisai/GCB2/taskList/ActionModal.vue'
import BachDialogue from './BachDialogue.vue'
import {
    getActivationFilterValues,
    getClientsByQuery,
    getMaxClientsCount,
    getVisitCountFilterValues,
    getWhatsappBasisSubsystemStatus,
} from '@root/src/api/chisai/GCB2'
import DataFilterBackend from '@root/src/components/inputs/DataFilterBackend.vue'
import DataFilterBackendOutlined from '@root/src/components/inputs/DataFilterBackendOutlined.vue'
import _, { omit } from 'lodash'
import moment from 'moment'
import PhoneFilter from '@root/src/components/chisai/GCB2/taskList/PhoneFilter.vue'
import ErrorModal from '@root/src/components/chisai/GCB2/taskList/ErrorModal.vue'
import ServiceBanDialog from '@root/src/components/chisai/GCB2/taskList/ServiceBanDialog.vue'
import ClientBanDialog from '@root/src/components/chisai/GCB2/taskList/ClientBanDialog.vue'
import { localStorageCache } from '@/helpers/localStorageCache'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { useTaskListBatchModeStore } from '@root/src/store/stores/gcb2/taskListBatchMode'
import { mapStores } from 'pinia'
import BatchModeHeaderCheckbox from '@root/src/components/chisai/GCB2/taskList/BatchModeHeaderCheckbox.vue'
import BatchModeRowCheckbox from '@root/src/components/chisai/GCB2/taskList/BatchModeRowCheckbox.vue'
import SendMessageErrorBadge from '@root/src/components/chisai/GCB2/taskList/SendMessageErrorBadge.vue'
import websocket from '@root/src/websockets'
import actions from '@root/src/components/chisai/GCB2/taskList/actions'
import messageVarsService from '@root/src/helpers/gcb2/messageVars'
import DikidiLink from '@root/src/components/iconLinks/DikidiLink.vue'
import AmoCrmLink from '@root/src/components/iconLinks/AmoCrmLink.vue'
import { CONNECTOR_TYPES, MESSAGING_CONNECTOR_TYPES } from '@root/src/vars/general'
import BatchModeAddDailyLimitModal from '@root/src/components/chisai/GCB2/taskList/BatchModeAddDailyLimitModal.vue'
import BaseInputOutlined from '@root/src/components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '@root/src/components/inputs/CustomActivatorSelect.vue'
import DemoSubView from '@/views/GCB2/subViews/DemoSubView.vue'
import { useProject } from '@root/src/hooks/useProject'
import { useBillingStore } from '@root/src/store/stores/billing'
import { getCurrentInstance } from 'vue'
import ChatLink from '@root/src/components/iconLinks/ChatLink.vue'

const STATUSES_ORDER = [
    { status: TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY, order: 1 },
    { status: TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY, order: 2 },
    { status: TASKLIST_STATUSES.NEED_TO_CONTACT, order: 3 },
    { status: TASKLIST_STATUSES.COMMUNICATION_ERROR, order: 4 },
    { status: TASKLIST_STATUSES.IN_PROGRESS, order: 5 },
    { status: TASKLIST_STATUSES.CONTACTED_AND_DONT_COME, order: 6 },
    { status: TASKLIST_STATUSES.CONTACTED_AND_COME, order: 7 },
    { status: TASKLIST_STATUSES.DONT_REMIND, order: 8 },
    { status: TASKLIST_STATUSES.DONT_REMIND_SERVICE, order: 9 },
    { status: TASKLIST_STATUSES.POSTPONED_COMMUNICATION, order: 10 },
]
const NEED_TO_CONTACT_STATUSES = [
    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
    TASKLIST_STATUSES.NEED_TO_CONTACT,
]
const DEFAULT_SORT_COLUMN = 'activation'
const loyalitySort = (a, b) => {
    return a.visitCount - b.visitCount
}
const communicationSort = (a, b) => {
    return moment(a.date).unix() - moment(b.date).unix()
}
const activationSort = (a, b) => {
    if (a.name < b.name) {
        return -1
    }
    if (a.name > b.name) {
        return 1
    }
    return 0
}
export default {
    name: 'GCB2TaskList',
    components: {
        BachDialogue,
        DemoSubView,
        YClientsLink,
        ProfSalonLink,
        dikidiLink,
        PhoneLink,
        TextField,
        VisitCountFilter,
        ActionsContextMenu,
        DataFilterBackend,
        DataFilterBackendOutlined,
        ErrorModal,
        PhoneFilter,
        SonlineLink,
        ServiceBanDialog,
        BatchModeHeaderCheckbox,
        BatchModeRowCheckbox,
        SendMessageErrorBadge,
        AltegioLink,
        DikidiLink,
        AmoCrmLink,
        ActionModal,
        BatchModeAddDailyLimitModal,
        BaseInputOutlined,
        CustomActivatorSelect,
        ClientBanDialog,
        ChatLink
    },
    props: {},
    data: () => ({
        TASKLIST_STATUSES,
        TASKLIST_ACTION_TYPES,
        NEED_TO_CONTACT_STATUSES,
        initialLoading: true,
        loading: true,
        dateBorders: null,
        serviceBanDialogIsOpened: false,
        clientBanDialogIsOpened: false,
        bachDialogIsOpened: false,
        expanded: [],
        currentItems: [],
        dateFormat: 'DD-MM-YYYY',
        selectedPhone: '',
        clientsList: [],
        selectedClients: [],
        selectedClientsState: null,
        selectedClientsMode: null,
        clientsFilterNewStateAndMode: null, //Нужен для сброса сосотояния
        selectedActivationsIds: [],
        minClientVisits: 0,
        maxClientVisits: 0,
        selectedVisitCount: [],
        activationsList: [],
        postponedDate: null,
        maxClientsCount: 0,
        recomendationBanListData: [],
        defaultSortBy: {},
        defaultSortDesc: {},
        defaultExpanded: [],
        defaultPage: {},
    }),
    setup(props, context) {
        const { project } = useProject()
        const root = getCurrentInstance().proxy
        const billingStore = useBillingStore()
        const currentTariff = billingStore.getCurrentTariffState(project.value.id)
        return {
            currentTariff,
            project,
        }
    },
    computed: {
        ...mapStores(useGcb2Store, useTaskListBatchModeStore),
        batchModeStatus() {
            return this.gcb2Store.batchModeStatus
        },

        batchModeButtonLabel() {
            return this.batchModeStatus ? 'ОБЫЧНЫЙ РЕЖИМ' : 'ПАКЕТНАЯ ОБРАБОТКА'
        },
        waBtnDisabled() {
            const whatsappStatus = this.$store.state.whatsappStatus
            if (!whatsappStatus || whatsappStatus?.statusSubsystem !== 'on') return false

            const btnStatus = whatsappStatus.aliveStatus === 'dead' ? true : false
            return btnStatus
        },
        batchModeSelectLimit() {
            return this.taskListBatchModeStore.messageLimit(this.project.id) || 0
        },
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector() {
            return this.project.connectors.find(con => con.connectorType === 'GCB2')
        },
        dashboardData() {
            return this.$store.getters[`GCB2/${t.GET_MANUAL_COMMUNICATION_DATA}`](this.project.id)
        },
        errorMessages() {
            return this.$store.getters[`GCB2/${t.GET_MESSAGE_ERRORS}`]
        },
        //Групирует данные по статусам
        groupedData() {
            let groupedObject = groupBy(this.dashboardData, 'status')
            const groupedArray = []
            for (let key in groupedObject) {
                const status = groupedObject[key][0].status
                groupedArray.push({
                    group: `${groupedObject[key][0].status}: ${groupedObject[key].length}`,
                    //Нужен для корректной отрисовки таблицы (чтобы item-key не менялся)
                    status,
                    data: groupedObject[key],
                })
            }
            //Сортируем по статусу
            if (!groupedArray.find(el => el.status === TASKLIST_STATUSES.DONT_REMIND_SERVICE)) {
                groupedArray.push({
                    group: `${TASKLIST_STATUSES.DONT_REMIND_SERVICE}: 0`,
                    status: TASKLIST_STATUSES.DONT_REMIND_SERVICE,
                    data: [],
                })
            }
            if (!groupedArray.find(el => el.status === TASKLIST_STATUSES.DONT_REMIND)) {
                groupedArray.push({
                    group: `${TASKLIST_STATUSES.DONT_REMIND}: 0`,
                    status: TASKLIST_STATUSES.DONT_REMIND,
                    data: [],
                })
            }
            return groupedArray.sort((a, b) => {
                const status1 = STATUSES_ORDER.find(el => a.status === el.status)
                const status2 = STATUSES_ORDER.find(el => b.status === el.status)
                return status1?.order - status2?.order
            })
        },
        displayedGroupedData() {
            if (!this.batchModeStatus) {
                return this.groupedData
            } else {
                return this.groupedData.filter(el => el.status === this.batchModeStatus)
            }
        },
        allClientsSelected() {
            return this.selectedClients.length === this.clientsList.length
        },
        allActivationsSelected() {
            return this.selectedActivationsIds.length === this.activationsList.length
        },
        footerProps() {
            if (this.dashboardData.length > 100) {
                return { 'items-per-page-options': [10, 15, 50, 100] }
            } else {
                return { 'items-per-page-options': [10, 15, 50, 100, -1] }
            }
        },
        activeItemId() {
            return this.gcb2Store.sidebar.communicationId
        },
        highlitedRowId() {
            if (this.gcb2Store.sidebar.section === SIDEBAR_SECTIONS.clientInfo) {
                return this.activeItemId
            } else {
                return null
            }
        },
        tableHeaders() {
            let headers = [
                { text: 'Клиент', value: 'clientNameWithNum' /*  width: '10%' */ },
                { text: 'Лояльность', value: 'loyality', sort: loyalitySort /*  width: '40%' */ },
                { text: 'Активация', value: 'activation', sort: activationSort /* width: '15%' */ },
                { text: 'Последнее посещение', value: 'lastVisitDate' /* width: '15%' */ },
                { text: '', value: 'actions', width: '1%' },
            ]
            if (this.connector.hideLoyality) {
                headers = headers.filter(el => el.value !== 'loyality')
            }
            if (!this.connector.showLastVisitedColumn) {
                headers = headers.filter(el => el.value !== 'lastVisitDate')
            }
            return headers
        },
        //Для строк со статусом contactedAndCome и ContactedAndDontCome
        tableHeadersContacted() {
            return [
                { text: 'Клиент', value: 'clientNameWithNum' /*  width: '10%' */ },
                { text: 'Лояльность', value: 'loyality', sort: loyalitySort /*  width: '40%' */ },
                { text: 'Активация', value: 'activation', sort: activationSort /* width: '15%' */ },
                { text: 'Коммуникация', value: 'communication', sort: communicationSort /*  width: '10%' */ },
                { text: '', value: 'actions', width: '1%' },
            ].filter(el => (this.connector.hideLoyality ? el.value !== 'loyality' : true))
        },
        tableHeadersNeedToContactPreviously() {
            return [
                { text: 'Клиент', value: 'clientNameWithNum', width: '10%' },
                { text: 'Активация', value: 'activation', sort: activationSort, width: '15%' },
                { text: 'Коммуникация', value: 'communication', sort: communicationSort, width: '10%' },
                { text: 'Отложена на', value: 'postponeTo', width: '10%' },
                { text: 'Комментарий', value: 'comment', width: '15%' },
                { text: '', value: 'actions', width: '1%' },
            ]
        },
        tableHeadersClientBan() {
            return [
                { text: 'Клиент', value: 'clientNameWithNum' /*  width: '10%' */ },
                { text: 'Лояльность', value: 'loyality', sort: loyalitySort /*  width: '40%' */ },
                { text: 'Коммуникация', value: 'communication', sort: communicationSort },
                { text: 'Комментарий', value: 'comment' },
                { text: '', value: 'actions', width: '1%' },
            ].filter(el => (this.connector.hideLoyality ? el.value !== 'loyality' : true))
        },
        tableHeadersServiceBan() {
            return [
                { text: 'Услуга', value: 'serviceTitle' /*  width: '10%' */ },
                { text: '', value: 'actions', width: '1%' },
            ]
        },
        batchModeSelectedCommunicationsIds() {
            return this.taskListBatchModeStore.selectedCommunicationsIds
        },
        containerTarget() {
            return this.$route.query.scrollTo
        },
        statusTarget() {
            return this.containerTarget.replace('#', '')
        },
    },
    watch: {
        '$route.query.activations'(val) {
            if (val) {
                this.setInitialData()
            }
        },
        '$route.query.section'(val) {
            localStorageCache.setProjectCache(this.project.id, 'taskListCache', 'selectedRowId', null)
            localStorage.setItem('pkgStatus', '')
        },
        'project.timestamps.communicationUpdate': {
            immediate: true,
            handler(val) {
                this.setInitialData()
            },
        },
        expanded(val) {
            for (const status in TASKLIST_STATUSES) {
                if (val.some(el => el.status === TASKLIST_STATUSES[status])) {
                    localStorageCache.setProjectCache(
                        this.project.id,
                        'taskListCache',
                        `tables.${TASKLIST_STATUSES[status]}.expanded`,
                        true
                    )
                } else {
                    localStorageCache.setProjectCache(
                        this.project.id,
                        'taskListCache',
                        `tables.${TASKLIST_STATUSES[status]}.expanded`,
                        false
                    )
                }
            }
        },
        batchModeStatus(val) {
            if (!val) {
                this.taskListBatchModeStore.selectedCommunications = []
            }
        },
        loading(val) {
            // После обновления расчётов открываем пакетную обработку по сохранённому статусу когда закончится загрузка
            if (!val && localStorage.getItem('pkgStatus')) {
                this.gcb2Store.openSidebar({
                    section: 'batchMode',
                })
                this.gcb2Store.setBatchModeStatus(localStorage.getItem('pkgStatus'))
                localStorage.setItem('pkgStatus', '')
                localStorageCache.setProjectCache(this.project.id, 'taskListCache', 'selectedRowId', null)
            }
            if (!val) {
                this.scrollToTarget()
            }
        },
    },
    methods: {
        checkRole,
        batchModeCheckboxClick(val, item) {
            if (val) {
                this.taskListBatchModeStore.selectedCommunications.push(item)
            } else {
                this.taskListBatchModeStore.selectedCommunications = this.taskListBatchModeStore.selectedCommunications.filter(
                    el => el.communicationId !== item.communicationId
                )
            }
        },
        getClientRef,
        getColorClass(item) {
            if (item.color === 'deleted') {
                return 'deleted'
            } else if (item.color === 'contact') {
                return 'contact'
            } else if (item.color === 'changed') {
                return 'changed'
            } else return null
        },
        getShowBatchModeButton(status) {
            //TODO: импортировать константу из @dataheroes/messages
            return (
                NEED_TO_CONTACT_STATUSES.includes(status) &&
                MESSAGING_CONNECTOR_TYPES.some(type =>
                    this.project.connectors.find(connector => connector.connectorType === type)
                ) &&
                this.checkRole(this.project.id, 'gcb2:tasklist:massSend:view')
            )
        },
        getHighlightedClass(item) {
            if (item.communicationId === this.highlitedRowId) {
                return 'expanded-row GCB2-highlited-row'
            } else {
                return 'expanded-row'
            }
        },
        async setMinMaxVisitCountInterval() {
            const range = (await getVisitCountFilterValues({ projectId: this.project.id })).data
            this.minClientVisits = range.min
            this.maxClientVisits = range.max
        },
        async setMaxClientCount() {
            const maxCount = (await getMaxClientsCount({ projectId: this.project.id }))?.data.count
            this.maxClientsCount = maxCount
        },
        async setActivationsList() {
            this.activationsList = (await getActivationFilterValues({ projectId: this.project.id })).data
        },
        selectAllActivations() {
            this.selectedActivationsIds = this.activationsList.filter(el => !el.automatic).map(el => el.id)
        },
        selectActivationsFromUrl() {
            const activation = this.$router.currentRoute.query.activations
            //Массив, потому что возможно придется отображать несколько активаций
            const idsToSelect = [Number(activation)]
            this.selectedActivationsIds = this.activationsList
                .filter(el => idsToSelect.includes(el.id))
                .map(el => el.id)
        },
        async applyFilters() {
            const filters = {
                visitCount: { min: this.selectedVisitCount[0], max: this.selectedVisitCount[1] },
                phone: this.selectedPhone,
                activations: this.selectedActivationsIds,
                clients: {
                    state: this.selectedClientsState,
                    mode: this.selectedClientsMode,
                    data: this.selectedClients.map(el => el.id),
                },
            }
            this.loading = true
            await this.$store.dispatch(`GCB2/${t.FETCH_AND_ADD_MANUAL_COMMUNICATION_DATA}`, {
                projectId: this.project.id,
                filters: filters,
            })

            this.$store.state[this.project.id] = { filters: filters }
            this.gcb2Store.closeSidebar()
            this.loading = false
        },
        openInNewTab,
        //Решает какие столбцы отображать в определенном статусе
        getTableHeaders(item) {
            if (
                item.status === TASKLIST_STATUSES.CONTACTED_AND_COME ||
                item.status === TASKLIST_STATUSES.CONTACTED_AND_DONT_COME
            ) {
                return this.tableHeadersContacted
            } else if (
                item.status === TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY ||
                item.status === TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY ||
                item.status === TASKLIST_STATUSES.POSTPONED_COMMUNICATION
            ) {
                return this.batchModeStatus
                    ? [
                          {
                              text: '',
                              value: 'checkboxes',
                              width: '56px',
                              sortable: false,
                              class: 'p-12px',
                          },
                          ...this.tableHeadersNeedToContactPreviously,
                      ]
                    : this.tableHeadersNeedToContactPreviously
            } else if (item.status === TASKLIST_STATUSES.DONT_REMIND) {
                return this.tableHeadersClientBan
            } else if (item.status === TASKLIST_STATUSES.DONT_REMIND_SERVICE) {
                return this.tableHeadersServiceBan
            } else {
                return this.batchModeStatus
                    ? [
                          {
                              text: '',
                              value: 'checkboxes',
                              width: '56px',
                              sortable: false,
                              class: 'p-12px',
                          },
                          ...this.tableHeaders,
                      ]
                    : this.tableHeaders
            }
        },
        setAllVisitCountRange() {
            this.selectedVisitCount = [this.minClientVisits, this.maxClientVisits]
        },
        setFiltersByStore() {
            const filters = this.$store.state[this.project.id]
                ? this.$store.state[this.project.id]['filters']
                : null
            if (filters) {
                this.selectedActivationsIds = filters.activations
                this.selectedVisitCount = [filters.visitCount.min, filters.visitCount.max]
            }
        },
        async changeClientItems(query) {
            if (!query) {
                this.clientsList = []
            } else {
                this.clientsList = (await getClientsByQuery({ projectId: this.project.id, query })).data
            }
        },
        dropFilters() {
            this.selectAllActivations()
            this.setAllVisitCountRange()
            this.clientsFilterNewStateAndMode = { state: 'ALL', mode: 'EXCLUDE' }
            this.selectedPhone = ''
        },
        rowClick(item) {
            if (this.batchModeStatus) {
                const value = !this.batchModeSelectedCommunicationsIds.includes(item.communicationId)
                const phone = item.hrefInfo.find(e => e.connectorType === 'tel')?.phone
                if (!phone) return
                const disabled =
                    this.batchModeSelectedCommunicationsIds.length >= this.batchModeSelectLimit &&
                    !this.batchModeSelectedCommunicationsIds.includes(item.communicationId)

                if (!disabled) {
                    this.batchModeCheckboxClick(value, item)
                }
                if (disabled) {
                    this.taskListBatchModeStore.setAddDailyLimitModalOpened(true)
                }
            } else {
                this.gcb2Store.openSidebar({
                    type: 'tasklist',
                    item,
                    section: 'clientInfo',
                    communicationId: item.communicationId,
                })
                localStorageCache.setProjectCache(
                    this.project.id,
                    'taskListCache',
                    'selectedRowId',
                    item.communicationId
                )
            }
        },
        async setInitialData() {
            this.$store.commit(`GCB2/${t.CLEAR_MANUAL_COMMUNICATION_DATA}`)
            this.initialLoading = true
            this.gcb2Store.closeSidebar()
            this.gcb2Store.setBatchModeStatus(null)
            this.taskListBatchModeStore.selectedLimit =
                this.connector?.batchModeSelectedLimit ?? DEFAULT_TASKLIST_BATCH_MODE_SELECT_LIMIT
            await this.setActivationsList()
            if (this.$router.currentRoute.query.activations) {
                this.dropFilters()
                this.selectActivationsFromUrl()
                this.$router.push({
                    path: this.$router.currentRoute.path,
                    query: omit(Object.assign({}, this.$router.currentRoute.query), 'activations'),
                })
            } else if (this.$store.state[this.project.id]) {
                this.setFiltersByStore()
            } else {
                this.setAllVisitCountRange()
                this.selectAllActivations()
            }

            await Promise.all([this.setMinMaxVisitCountInterval(), this.setMaxClientCount()])
            this.gcb2Store.fetchCommunicationTags(this.project.id)

            await this.applyFilters()
            this.initLocalStorageCache()
            this.taskListBatchModeStore.selectedCommunications = []

            this.initialLoading = false
        },
        rowsOrderChanged(e) {
            this.currentItems = e
            this.$store.commit(`GCB2/${t.SET_MANUAL_COMMUNICATION_CURRENT_ITEMS}`, {
                data: e.map(el => Object.assign({}, el)),
            })
        },
        updateSortBy(item, sort) {
            localStorageCache.setProjectCache(
                this.project.id,
                'taskListCache',
                `tables.${item.status}.sort.by`,
                sort
            )
        },
        updateSortDesc(item, sort) {
            localStorageCache.setProjectCache(
                this.project.id,
                'taskListCache',
                `tables.${item.status}.sort.desc`,
                sort
            )
        },
        updatePagination(item, value) {
            localStorageCache.setProjectCache(
                this.project.id,
                'taskListCache',
                `tables.${item.status}.page`,
                value.page
            )
        },
        initLocalStorageCache() {
            const projectCache = localStorageCache.getProjectCache(this.project.id, 'taskListCache')
            const selectedRow = this.dashboardData.find(
                el => el.communicationId === projectCache.selectedRowId
            )

            if (!selectedRow) {
                localStorageCache.setProjectCache(this.project.id, 'taskListCache', 'selectedRowId', null)
            }
            if (projectCache.selectedRowId && selectedRow) {
                this.gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: selectedRow,
                    section: 'clientInfo',
                    communicationId: projectCache.selectedRowId,
                })
            }
            for (const tableStatus in projectCache.tables || {}) {
                const statusExists = Boolean(this.dashboardData.find(el => el.status === tableStatus))
                if (statusExists) {
                    this.defaultSortBy[tableStatus] = projectCache.tables[tableStatus]?.sort?.by
                    this.defaultSortDesc[tableStatus] = projectCache.tables[tableStatus]?.sort?.desc
                    //this.defaultPage[tableStatus] = projectCache.tables[tableStatus]?.page
                    this.defaultPage[tableStatus] = 1
                    if (projectCache.tables[tableStatus]?.expanded) {
                        this.expanded.push(this.groupedData.find(el => el.status === tableStatus))
                    }
                } else {
                    this.defaultSortBy[tableStatus] = DEFAULT_SORT_COLUMN
                    this.defaultSortDesc[tableStatus] = null
                    this.defaultPage[tableStatus] = 1
                }
            }
        },
        changeBatchModeStatus(status) {
            this.bachDialogIsOpened = this.batchModeStatus ? false : true
            if (this.batchModeStatus) {
                this.gcb2Store.closeSidebar()
                this.gcb2Store.setBatchModeStatus(null)
            } else {
                localStorage.setItem('pkgStatus', status)
                this.gcb2Store.openSidebar({
                    section: 'batchMode',
                })
                this.gcb2Store.setBatchModeStatus(status)
            }
        },
        getChatTextMessage(manualCommunication) {
            return messageVarsService.resolveVars(
                manualCommunication.textMessage,
                this.connector,
                manualCommunication
            )
        },
        getError(item) {
            let errorMessage = this.errorMessages.find(
                msg => msg.message.communicationId === item.communicationId
            )
            if (errorMessage) {
                return errorMessage.reason
            }
        },
        async scrollToTarget() {
            if (this.containerTarget) {
                this.groupedData.forEach(gd => {
                    const tableName = gd.group.split(':')[0]
                    localStorageCache.setProjectCache(
                      this.project.id,
                      'taskListCache',
                      `tables.${tableName}.expanded`,
                      false
                    )
                })
                this.expanded = [];

                localStorageCache.setProjectCache(
                    this.project.id,
                    'taskListCache',
                    `tables.${
                        TASKLIST_STATUSES[Object.keys(TASKLIST_STATUSES).find(k => k === this.statusTarget)]
                    }.expanded`,
                    true
                )
                await this.initLocalStorageCache()
                setTimeout(() => {
                    this.$scrollTo(this.containerTarget, 800, {
                        offset: -210,
                    })
                    this.removeQueryParam(['scrollTo', 'activations'])
                }, 500)
            }
        },
        removeQueryParam(param) {
            const query = { ...this.$route.query }
            if (Array.isArray(param)) {
                for (const el in param) {
                    delete query[param[el]]
                }
            } else {
                delete query[param]
            }

            this.$router.replace({ query })
        },
    },
    mounted() {
        if (!checkRole(this.project.id, 'gcb2:tasklist:view')) {
            this.$router.replace('/')
        }
        this.bachDialogIsOpened = false
        websocket.socket.on('TASKLIST_ACTION', payload => {
            if (payload.projectId !== this.project.id) return
            if (payload.communicationId) {
                payload.communicationId = String(payload.communicationId)
            }
            if (payload?.actionData?.communicationId) {
                payload.actionData.communicationId = String(payload.actionData.communicationId)
            }
            actions.websocketAction(this, payload)
        })
        websocket.socket.on('NEW_MISSED_CALL', async payload => {
            payload = camelize(payload);
            await this.$store.dispatch(`GCB2/${t.ADD_MISSED_CALL}`, {
                data: payload, projectId: payload.projectId
            })
        })
        if (this.containerTarget && !this.loading) {
            this.scrollToTarget()
        }
    },
    destroyed() {
        websocket.socket.off('TASKLIST_ACTION')
    },
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
::v-deep .expanded-table td
    padding-left: 12px !important
    padding-right: 12px !important

.table-buttons-wrapper
    position: relative
    display: flex
    flex-direction: row
    justify-content: flex-end

.deleted
    color: $gcb2-table-deleted-color

.contact
    color: $gcb2-table-contact-color

.changed
    color: $gcb2-table-changed-color

.filter
    margin-right: 24px
    margin-top: 18px

.data-control-btns
    margin-bottom: 26px !important

.cancel-filters-btn
    border: 1px solid #0000001F
    color: $text-color

.table-loading
    pointer-events: none
    filter: opacity(25%)

::v-deep .recomendations-table table
    max-width: 1016px !important
    stable-layout: fixed !important

::v-deep .expandable-table tr
    cursor: pointer

::v-deep .expanded-table tr
    cursor: pointer

::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content
    box-shadow: none

::v-deep.v-data-table > .v-data-table__wrapper
    overflow: visible !important

::v-deep .v-data-table.expandable-table > div > table > tbody > tr:nth-child(1) td
    border-top: thin solid $light-gray-2

::v-deep .expandable-row > td
    font-size: 16px
    font-weight: 500

.client-name
    font-weight: 500

.activation-group
    font-size: 12px

::v-deep .phone-link span
    font-size: 12px

::v-deep .v-data-table.expanded-table > div > table > tbody > tr:last-child td
    border-bottom: none !important

::v-deep .v-data-table.expandable-table > div > table > thead
    visibility: collapse

::v-deep thead th span
    background-color: $light-gray-3 !important
    font-size: 14px !important
    color: #000000DE

::v-deep .expanded-table tr:nth-child(even)
    background-color: $light-gray-3

::v-deep .p-12px
    padding-left: 12px !important
    padding-right: 12px !important
</style>
