
import Vue, { PropType } from 'vue'
import { Connector, Project, Tag } from '@/types/main'
import {
    deleteActivation,
    getActivations,
    getOverviewCardStat,
    getWhatsappBasisSubsystemStatus,
    setChangedActivationNotification,
    swapActivationOrder,
    swapActivationPriority,
    toogleIsRunning,
    updateActivation,
} from '../../../api/chisai/GCB2'
import SpinnerLoader from '../../../components/SpinnerLoader.vue'
import _, { findIndex } from 'lodash'
import OverviewCard from '../../../components/chisai/GCB2/overview/OverviewCard.vue'
import addActivationCard from '../../../components/chisai/GCB2/overview/AddActivationCard.vue'
import { CONNECTOR_TYPES, TAG_COLORS } from '../../../vars/general'
import { DEFAULT_MAX_ACTVATIONS_COUNT } from '../../../vars/GCB2'
import { checkRole } from '../../../helpers/roles'
import Tags from '../../../components/inputs/Tags/Tags.vue'
import { mod } from '../../../utils'
import { editActivationService } from '../../../services/editActivation'
import DemoSubView from './DemoSubView.vue'
import { useProject } from '@root/src/hooks/useProject'
import { useBillingStore } from '@root/src/store/stores/billing'

//IMPORTANT!!!!
// inc - увеличивет приоритет путем уменьшения значения приоритета (т.к приоритет1 > приоритет2)
//

const tagFilter = (card: any, selectedTags: string[]) => {
    if (selectedTags.length === 0) {
        return true
    }
    if (selectedTags.includes('_automatic') && !card.isAutomatic) {
        return false
    }
    if (selectedTags.includes('_manual') && card.isAutomatic) {
        return false
    }
    const customSelectedTags = selectedTags.filter(tagId => tagId !== '_automatic' && tagId !== '_manual')
    return customSelectedTags.every((tagId: string) => card.tags.includes(tagId))
}

export default Vue.extend({
    components: { SpinnerLoader, OverviewCard, addActivationCard, Tags, DemoSubView },

    data: () => ({
        cards: [] as any[],
        loading: true,
        cardsUpdatedTimestamp: 0,
        selectedTags: [],
        highlightedActivationId: null as number | null,
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        GCB2Connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        activeOrderedCards(): any[] {
            return _.orderBy(this.activeCards, 'priority').filter(card => tagFilter(card, this.selectedTags))
        },
        inactiveOrderedCards(): any[] {
            return _.orderBy(this.inactiveCards, 'order').filter(card => tagFilter(card, this.selectedTags))
        },
        activeCards(): any[] {
            return this.cards.filter(el => el.isRunning)
        },
        inactiveCards(): any[] {
            return this.cards.filter(el => !el.isRunning)
        },
        maxActiveCardsCount(): number {
            return this.GCB2Connector?.maxActivationsCount || DEFAULT_MAX_ACTVATIONS_COUNT
        },
        displayAddActivationCard(): boolean {
            return this.activeCards.length < this.maxActiveCardsCount
        },
        minMaxOrder(): { min: number; max: number } {
            const min = _.minBy(this.inactiveCards, 'order').order
            const max = _.maxBy(this.inactiveCards, 'order').order
            return { min, max }
        },
        waBtnDisabled(): boolean {
            const whatsappStatus = this.$store.state.whatsappStatus
            if (!whatsappStatus || whatsappStatus?.statusSubsystem !== 'on') return false

            const btnStatus = whatsappStatus.aliveStatus === 'dead' ? true : false
            return btnStatus
        },

        tags(): Tag[] {
            return [
                { id: '_manual', name: 'Ручная коммуникация', color: '#2A3B5199' },
                { id: '_automatic', name: 'Авторассылка', color: '#F3B12C' },
            ].concat(
                _.uniq(this.cards.flatMap(el => el.tags)).map((tagName, i) => ({
                    id: tagName,
                    name: tagName,
                    color: TAG_COLORS[mod(i, TAG_COLORS.length)],
                }))
            )
        },
    },
    watch: {
        'project.timestamps.communicationUpdate': {
            immediate: true,
            handler(val) {
                this.setCards()
            },
        },
        loading(v) {
            console.log('loading', v)
            if (!v) {
                setTimeout(() => {
                    if (this.$router.currentRoute.query.highlight) {
                        this.highlightActivation(Number(this.$router.currentRoute.query.highlight))
                    }
                }, 1000)
            }
        },
    },
    methods: {
        checkRole,
        async changePriority({ activationId, type }: { activationId: number; type: 'inc' | 'dec' }) {
            const card = this.cards.find(el => el.activationId === activationId)
            if (this.cantChangePriority(card, type)) {
                return
            }
            this.$store.commit('startLoading')
            const { data, error } = await swapActivationPriority({
                projectId: this.project.id,
                id: activationId,
                type,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка')
                this.$store.commit('endLoading')
                return
            }
            const cardsCopy = [...this.cards]
            const cardToUpdate1 = cardsCopy.find(el => el.activationId === data![0].id)
            const cardToUpdate2 = cardsCopy.find(el => el.activationId === data![1].id)
            cardToUpdate1.priority = data![0].priority
            cardToUpdate2.priority = data![1].priority
            this.cards = cardsCopy
            this.$store.commit('endLoading')
        },
        async changeOrder({ activationId, type }: { activationId: number; type: 'inc' | 'dec' }) {
            const card = this.cards.find(el => el.activationId === activationId)
            if (this.cantChangeOrder(card, type)) {
                return
            }
            this.$store.commit('startLoading')
            const { data, error } = await swapActivationOrder({
                projectId: this.project.id,
                id: activationId,
                type,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка')
                this.$store.commit('endLoading')
                return
            }
            const cardsCopy = [...this.cards]
            const cardToUpdate1 = cardsCopy.find(el => el.activationId === data![0].id)
            const cardToUpdate2 = cardsCopy.find(el => el.activationId === data![1].id)
            cardToUpdate1.order = data![0].order
            cardToUpdate2.order = data![1].order
            this.cards = cardsCopy
            this.$store.commit('endLoading')
        },
        async changeIsRunning({ activationId, value }: { activationId: number; value: boolean }) {
            this.$store.commit('startLoading')
            await setChangedActivationNotification({
                projectId: this.project.id,
                active: true,
            })
            const { data, error } = await toogleIsRunning({
                projectId: this.project.id,
                id: activationId,
                isRunning: value,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка')
                this.$store.commit('endLoading')
                return
            }
            const cardsCopy = [...this.cards]
            data?.forEach(el => {
                const cardToUpdate = cardsCopy.find(card => card.activationId === el.id)
                cardToUpdate.priority = el.priority
                cardToUpdate.isRunning = el.isRunning
                if (activationId === el.id) cardToUpdate.isRunningChangedAt = el.isRunningChangedAt
            })
            this.cards = cardsCopy
            this.$store.commit('endLoading')
        },
        async deleteActivation(activationId: number) {
            this.$store.commit('startLoading')
            const { data, error } = await deleteActivation({
                projectId: this.project.id,
                id: activationId,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка')
                this.$store.commit('endLoading')
                return
            }

            const cardsCopy = [...this.cards]
            data?.forEach(el => {
                const cardToUpdate = cardsCopy.find(card => card.activationId === el.id)
                cardToUpdate.priority = el.priority
                cardToUpdate.isRunning = el.isRunning
            })
            const indexToDelete = cardsCopy.findIndex(el => el.activationId === activationId)
            cardsCopy.splice(indexToDelete, 1)
            this.cards = cardsCopy
            this.$store.commit('endLoading')
        },
        async copyActivation(activationId: number) {
            this.$store.commit('startLoading')
            const createdActivation = await editActivationService.copyActivation(
                this.project.id,
                this.project,
                activationId
            )
            await this.setCards(true)
            await this.$store.commit('endLoading')
            this.highlightActivation(createdActivation!.id)
        },
        highlightActivation(id: number) {
            this.$scrollTo(`#activation-card-${id}`, 300, {
                onDone: () => {
                    this.highlightedActivationId = id
                    setTimeout(() => {
                        this.highlightedActivationId = null
                    }, 1500)
                },
            })
        },
        cantChangePriority(card: any, type: 'inc' | 'dec'): boolean {
            const newPriority = type === 'inc' ? card.priority - 1 : card.priority + 1
            return (
                newPriority < 1 ||
                newPriority > this.maxActiveCardsCount ||
                newPriority > this.activeCards.length
            )
        },
        cantChangeOrder(card: any, type: 'inc' | 'dec'): boolean {
            const newOrder = type === 'inc' ? card.order - 1 : card.order + 1
            return newOrder < this.minMaxOrder.min || newOrder > this.minMaxOrder.max
        },
        async setCards(ignoreLoading?: boolean) {
            if (!ignoreLoading) this.loading = true
            const cardStat = (await getOverviewCardStat({ projectId: this.project.id })).data
            this.cards = cardStat!.map(el => ({
                activationId: el.activationId,
                title: el.activationName,
                description: el.activationDescription || '',
                isAutomatic: el.isAutomatic,
                allCount: el.totalForCardStat,
                notProcessedCount: el.notProcessedForCardStat,
                errors: el.errorsForCardStat,
                disabled: el.disabled,
                isRunning: el.isRunning,
                isRunningChangedAt: el.isRunningChangedAt,
                priority: el.priority,
                order: el.order,
                tags: el.tags,
                time: el.time,
                timezone: el.timezone,
                updatedAt: el.updatedAt
            }))
            this.cardsUpdatedTimestamp = this.project.timestamps.communicationUpdate
            if (!ignoreLoading) this.loading = false
        },
        goToTaskList(activationId: number) {
            this.$router.push(
                `${this.$router.currentRoute.path}?section=taskList&activations=${activationId}`
            )
        },
    },
    setup(props, context) {
        const { project } = useProject()
        const billingStore = useBillingStore()
        const currentTariff = billingStore.getCurrentTariffState(project.value.id)
        return {
            project,
            currentTariff,
        }
    },

    async mounted() {
        const data = await getWhatsappBasisSubsystemStatus(this.project.id)
        const whatsappStatus = data.data
        this.$store.state.whatsappStatus = whatsappStatus
        // const activations = (await getActivations(this.project.id)).data
        // const autoMailActivations = activations.filter(el => {
        //     if (el.extra)
        //     if(el.extra.automail)
        //     if(el.extra.automail.isDeleted === false)
        //     return true
        // })

        // if(this.waBtnDisabled)
        // for (let el of autoMailActivations){
        //     el.extra.automail.isDeleted = true
        //     await updateActivation(el)
        // }

        if (!checkRole(this.project.id, 'gcb2:tasklist:view')) {
            this.$router.replace('/')
        }
    },
})
